import styled from 'styled-components'

export const WarningTextArea = styled.div`
    flex: 1;
    height: 50px;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin: auto;
    left: calc(50% - 120px);
    top: 10px;
`

