import React from 'react';

import { StyledContent } from './styles';

type Props = {
    children: React.ReactNode;
};

const Content: React.FC<Props> = ({ children }) => {
    return <StyledContent>
        {children}
    </StyledContent>;
};

export default Content;
