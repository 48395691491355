import { Alert } from 'antd';
import { WarningTextArea } from './style';

export const EnvironmentAlert = () => {
    return  (
        <WarningTextArea>
				<Alert
					message="Ambiente de Homologação"
					type="error"
					style={{
						background: '#FFC0C1',
						width: 240,
						textAlign: 'center',
						zIndex: 9999
					}}
				/>
        </WarningTextArea>
    );
};
