import axios, { AxiosRequestConfig } from 'axios';
import pkg from '../../package.json';
import env from '../env';

const api = axios.create({
    baseURL: env.API_URL,
});

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const { headers } = config;
    headers['x-app-version'] = pkg.version || '',
    headers['x-platform'] = "admin"
    headers['x-user-id'] = localStorage.getItem('@NeerHouse:userId') || ''
    return config;
});

api.prototype.Cancel = () => {
    axios.CancelToken.source();
};

export default api;
